import './App.scss';
import { ConfigProvider, Input, Button, message, Modal } from 'antd';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import Igame from './hardhat/artifacts/contracts/game.sol/TENTACLEBank.json';
import Token from './hardhat/artifacts/contracts/game.sol/Token.json';
import copy from 'copy-to-clipboard';

const Decimal = require('decimal.js');

let ChainID;
let Providers;
let GameContract;
let TENTACLEContract;
let account;

export default function App() {
  const Chainid = 56;
  const GameAddr = '0x4E09e5CD1D02ed5a960211ab70e67548D6A18b69';
  const TENTACLEAddr = '0x2f8e8Ec80355aCFEe8CcBBA40d895Db61759df3D';

  const [ConnectWalletLoading, setConnectWalletLoading] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [Accounts, setAccounts] = useState('连接钱包');
  const [UserInfo, setUserInfo] = useState(['--', '--', '--', '--', '--', '--', '--']);
  const [QueryUp, setQueryUp] = useState('');
  const [TENTACLE, setTENTACLE] = useState('--');
  const [mintButton, setMintButton] = useState('授权TENTACLE');
  const [MySuperior, setMySuperior] = useState('---');
  const [RefAddr, setRefAddr] = useState('');

  useEffect(() => {
    let addr = getQueryVariable('ref');
    if (addr == false) {
      addr = TENTACLEAddr;
    }
    setRefAddr(addr);
    //console.log("ADDR:", addr);
    return () => {};
  }, []);

  function isMetaMaskInstalled() {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  const ConnectWallet = async () => {
    try {
      if (isMetaMaskInstalled()) {
        if (ChainID == Chainid) {
          return;
        }
        setConnectWalletLoading(true);

        Providers = new ethers.providers.Web3Provider(window.ethereum);

        let ChainInfos = await Providers.getNetwork();
        ChainID = ChainInfos.chainId;

        await GetAccount();
        await chainChanged();
        setConnectWalletLoading(false);

        Providers.on('block', async () => {
          await baseInfo();
        });

        await window.ethereum.on('chainChanged', async function () {
          Providers = new ethers.providers.Web3Provider(window.ethereum);
          await chainChanged();
        });
        window.ethereum.on('accountsChanged', async function () {
          await GetAccount();
          Providers = new ethers.providers.Web3Provider(window.ethereum);
        });
      } else {
        message.warning('没有检测到钱包插件!');
        return;
      }
    } catch (err) {
      setConnectWalletLoading(false);
      console.log(err);
    }
  };

  let chainChanged = async () => {
    try {
      Providers = new ethers.providers.Web3Provider(window.ethereum);
      let ChainInfos = await Providers.getNetwork();
      ChainID = ChainInfos.chainId;
      if (ChainInfos.chainId != Chainid) {
        setAccounts('请切换至BSC链');
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x56' }]
        });
      } else {
        await GetAccount();
        await baseInfo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const GetAccount = async () => {
    try {
      await window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          setAccounts(accounts[0]);
          account = accounts[0];
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetTENTACLEUp = async () => {
    try {
      if (!Providers) {
        message.warning('请先连接钱包');
        return;
      }
      if (ChainID != Chainid) {
        message.warning('请先切换正确的链!');
        return;
      }
      if (!QueryUp) {
        message.warning('请输入地址');
        return;
      }
      let superior = await GameContract.getTENTACLEUp(QueryUp.toString());
      let modal = Modal.success();
      modal.update({
        title: '上级地址',
        content: superior.toString(),
        okText: '确认并复制',
        centered: true
      });
      copy(superior.toString());
    } catch (err) {
      console.log(err);
    }
  };

  const GetRefCODE = async () => {
    try {
      if (!Providers) {
        message.warning('请先连接钱包');
        return;
      }
      if (ChainID != Chainid) {
        message.warning('请先切换正确的链!');
        return;
      }
      let url = window.location.origin;
      //console.log(url + "?ref=" + account);

      copy(url + '?ref=' + account);
      message.success('复制成功');
    } catch (err) {
      console.log(err);
    }
  };

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  const ApproveAndMint = async () => {
    try {
      setBuyLoading(true);
      let quota = await TENTACLEContract.allowance(account, GameAddr);
      let Uprice = await GameContract.price();
      let U_price = new Decimal(Uprice.toString()).mul(new Decimal(10).pow('18'));
      if (quota >= U_price) {
        let tx = await GameContract.payToken(RefAddr);
        await tx.wait();
      } else {
        let tx = await TENTACLEContract.approve(GameAddr, '10000000000000000000');
        await tx.wait();
      }
      setBuyLoading(false);
    } catch (err) {
      setBuyLoading(false);
      console.log(err);
    }
  };

  const Withdraw = async () => {
    try {
      let tx = await GameContract.withdraw();
      await tx.wait();
      await baseInfo();
    } catch (err) {
      console.log(err);
    }
  };

  const GetApproveStatus = async () => {
    try {
      let quota = await TENTACLEContract.allowance(account, GameAddr);
      let Uprice = await GameContract.price();
      let U_price = new Decimal(Uprice.toString()).mul(new Decimal(10).pow('18'));
      if (quota >= U_price) {
        setMintButton('开始挖矿');
      } else {
        setMintButton('授权 TENTACLE');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const baseInfo = async () => {
    try {
      if (!Providers) {
        message.warning('请先连接钱包');
        return;
      }
      if (ChainID != Chainid) {
        message.warning('请先切换正确的链!');
        return;
      }
      GameContract = new ethers.Contract(GameAddr, Igame.abi, Providers.getSigner());
      TENTACLEContract = new ethers.Contract(TENTACLEAddr, Token.abi, Providers.getSigner());
      let Userinfo = await GameContract.getUser(account);
      let _user = Userinfo;
      //console.log("Userinfo", Userinfo);
      _user = ['', '', '', '', '', '', ''];
      _user[1] = Userinfo[1] == 0 ? 0 : new Decimal(Userinfo[1].toString()).div(new Decimal(10).pow('18')).toFixed(3);
      //_user[2] = format(1670095179 * 1000);
      //console.log(format(1670095179 * 1000))
      _user[2] = (Userinfo[2] == 0 ? "  ----" : format(Userinfo[2] * 1000));
      _user[3] = Userinfo[3] == 0 ? 0 : new Decimal(Userinfo[3].toString()).div(new Decimal(10).pow('18')).toFixed(3);
      _user[4] = Userinfo[4] == 0 ? 0 : new Decimal(Userinfo[4].toString()).div(new Decimal(10).pow('18')).toFixed(3);
      _user[5] = Userinfo[5];
      _user[6] = Userinfo[6] == 0 ? 0 : new Decimal(Userinfo[6].toString()).div(new Decimal(10).pow('18')).toFixed(3);

      setUserInfo(_user);

      let price = await GameContract.getTENTACLEs();
      //console.log("price", price)
      let _price = new Decimal(price.toString()).div(new Decimal(10).pow('18')).toFixed(3);
      setTENTACLE(_price.toString());

      await GetApproveStatus();

      let mysuperior = await GameContract.getTENTACLEUp(account);
      setMySuperior(mysuperior.toString());
      //console.log(_user);
    } catch (err) {
      console.log(err);
    }
  };

  function add0(m) {
    return m < 10 ? '0' + m : m;
  }

  function format(shijianchuo) {
    //shijianchuo是整数，否则要parseInt转换
    var time = new Date(shijianchuo);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
  }

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#ff7062' } }}>
      <div className="app w-100 h-100 overflow-auto slim-scroll">
        <header className="flex-vertical justify-content-center align-items-center overflow-hidden">
          <div className="w-100 d-flex justify-content-end pt-3 pr-3">
            <div>
              <Button onClick={ConnectWallet} loading={ConnectWalletLoading} danger>
                {Accounts.length < 42 ? Accounts : Accounts.substring(0, 5) + '...' + Accounts.substring(38, 42).toString()}
              </Button>
            </div>
          </div>
          <div className="img-box w-100 position-relative">
            <div className="text flex-vertical justify-content-center align-items-center">
              <h1 className="color-white">矿池</h1>
              <div className="color-thin">
                <span className="text-title">{UserInfo[6].toString()}</span>
                <span className="ml-2">USDT</span>
              </div>
            </div>
            <img className="w-100" src={require('./assets/img/top.png')} alt="" />
          </div>
          <div className="w-100 px-5">
            <div className="content bg-bg round-sm">
              <div className="px-4 py-4">
                <div className="flex-item align-items-center color-danger">
                  <div className="flex-label mr-2">数量</div>
                  <Input className="flex-content" readOnly value="10 USTD" />
                  <div className="flex-label mx-1">≈</div>
                  <div className="flex-label">{TENTACLE}</div>
                  <div className="flex-label ml-1">TENTACLE</div>
                </div>
                <div className="flex-center-center color-danger mt-3">
                  <div>昨日静态收益</div>
                  <div className="text-bold mx-1">1</div>
                  <div>USDT</div>
                </div>
                <div className="mt-3">
                  <Button type="primary" loading={buyLoading} onClick={ApproveAndMint}>
                    {mintButton}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="earning-wrapper position-relative round-sm px-5 color-white mt-5">
          <div className="earning-content bg-bg">
            <div className="title w-100 position-relative flex-center-center">
              <div className="w-100 h-100 absolute-0 flex-center-center text-bold pb-1 color-danger">累计收益</div>
              <img className="w-100" src={require('./assets/img/title.png')} alt="" />
            </div>
            <div className="position-relative px-4">
              <div className="absolute-0 w-100 h-100 pb-2-5 pl-6 flex-center-center">
                <div className="flex-vertical justify-content-center">
                  <div className="text-sm">等待取款</div>
                  <div className="text-center">
                    <span className="text-bold">{UserInfo[1].toString()}</span>
                    <span className="text-sm ml-2">USDT</span>
                  </div>
                </div>
              </div>
              <img className="w-100" src={require('./assets/img/img1.png')} alt="" />
            </div>
            <div className="position-relative px-4 mt-3">
              <div className="absolute-0 w-100 h-100 pb-2-5 pl-6 pt-2 flex-center-center">
                <div className="flex-vertical align-items-center justify-content-center">
                  <div className="text-sm">下次取款时间</div>
                  <div className="text-bold text-center">
                    <span>{UserInfo[2].toString()}</span>
                  </div>
                </div>
              </div>
              <img className="w-100" src={require('./assets/img/img2.png')} alt="" />
            </div>
            <div className="img3-box position-relative">
              <div className="absolute-0 w-100 h-100 pl-4-5 flex-center-center">
                <div className="flex-vertical justify-content-center">
                  <div className="text-center">
                    <span className="text-bold">{UserInfo[1].toString()}</span>
                  </div>
                  <div className="text-sm">累计收益</div>
                </div>
              </div>
              <div className="get-btn">
                <img className="h-100" src={require('./assets/img/img4.png')} alt="" />
              </div>
              <img
                className="w-100"
                src={require('./assets/img/img3.png')}
                alt=""
                onClick={async () => {
                  await Withdraw();
                }}
              />
            </div>
          </div>
          <div className="text-center color-danger mt-4">
            <span>累计返佣</span>
            <span className="mx-1">{UserInfo[4].toString()}</span>
            <span>USDT</span>
          </div>
        </section>
        <section className="team-wrapper position-relative round-sm px-5 color-white mt-5">
          <div className="team-content bg-bg pb-4">
            <div className="title w-100 position-relative flex-center-center">
              <div className="w-100 h-100 absolute-0 flex-center-center text-bold pb-1 color-danger">我的团队</div>
              <img className="w-100" src={require('./assets/img/title.png')} alt="" />
            </div>
            <div className="text-center color-danger mt-4">
              <span>团队中人数</span>
              <span className="mx-1">{UserInfo[5].toString()}</span>
              <span>人</span>
            </div>
            <div className="px-4 mt-3">
              <Input
                className="flex-content"
                size="large"
                placeholder="请输入查询地址"
                onChange={async (event) => {
                  setQueryUp(event.target.value);
                }}
              />
              <div className="mt-3">
                <Button
                  type="primary"
                  onClick={async () => {
                    await GetTENTACLEUp();
                  }}
                >
                  上级查询
                </Button>
              </div>
              <div className="text-center color-danger mt-4">
                <span>你的上级</span>
              </div>
              <Input className="flex-content" readOnly placeholder={MySuperior} />
              <div className="mt-3">
                <Button
                  type="primary"
                  onClick={async () => {
                    await GetRefCODE();
                  }}
                >
                  生成并复制我的邀请链接
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className="icon-wrapper py-4 flex-center-center">
          <div>
            <img src={require('./assets/img/icon1.png')} alt="" />
          </div>
          <div className="mx-3">
            <img src={require('./assets/img/icon2.png')} alt="" />
          </div>
          <div>
            <img src={require('./assets/img/icon3.png')} alt="" />
          </div>
        </section>
      </div>
    </ConfigProvider>
  );
}
